import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { message, Badge, Modal } from "antd";
import dayjs from "dayjs";

import * as api from "../../../../api/Local";
import AppButton from "../../../_shared/components/AppButton";
import AppInput from "../../../_shared/components/FormItems/AppInput";
import Table from "../../../_shared/components/Table";
import EditButton from "../../../_shared/components/Buttons/EditButton";
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton";
import { useStateValue } from "../../../_shared/context/AppStateStore";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import CouponsFormBottomSheet from "./CouponsFormBottomSheet";
import StyledPopconfirm from "../../../_shared/components/Popconfirm";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MenuOutlined } from "@ant-design/icons";

import Papa from "papaparse";
import moment from "moment";

// ----------------------------------------------------------------------------

function Coupons(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [{ token }] = useStateValue();

  const [
    coupons,
    fetchCoupons,
    loading,
    pagination,
    totalRecordsNumber,
    onFiltersChange,
  ] = usePaginatedRestResource("/coupons", token);

  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const [openSelectFile, setOpenSelectFile] = useState(null);
  const [importedCoupons, setImportedCoupons] = useState(null);

  const [sortedCoupons, setSortedCoupons] = useState(null);

  useEffect(() => {
    setSortedCoupons(coupons?.sort((a, b) => a.sortIndex - b.sortIndex));
  }, [coupons]);

  useEffect(() => {
    console.log("sorted coupons : ", sortedCoupons);
  }, [sortedCoupons]);
  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleCreateCoupon(post) {
    try {
      let body = {
        ...post,
      };
      console.log("body is : ", body);

      if (post.detailImage) {
        const uploadResponse = await api.uploadFile(post.detailImage);

        body.detailImage = uploadResponse?.url ?? body.detailImage;
      }
      if (post.image) {
        const uploadResponse = await api.uploadFile(post.image);

        body.image = uploadResponse?.url ?? body.image;
      }

      await api.createResource("/coupons", token, body);
      setSelectedCoupon(null);
      fetchCoupons();
      message.success("Coupon creato con successo");
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  async function handleUpdateCoupon(post, id) {
    try {
      let body = {
        ...post,
      };

      if (post.detailImage && typeof post.detailImage !== "string") {
        const oldImage = coupons?.find(
          (item) => item?.id === post.id
        )?.detailImage;

        const imageId = oldImage?.substring?.(oldImage?.lastIndexOf("/") + 1);

        const uploadResponse = await api.uploadFile(post.detailImage);

        body.detailImage = uploadResponse?.url ?? body.detailImage;
      }

      if (post.image && typeof post.image !== "string") {
        const oldImage = coupons?.find((item) => item?.id === post.id)?.image;

        const imageId = oldImage?.substring?.(oldImage?.lastIndexOf("/") + 1);

        const uploadResponse = await api.uploadFile(post.image);

        body.image = uploadResponse?.url ?? body.image;
      }

      await api.updateResource(
        `/coupons`,
        token,
        id ?? selectedCoupon.id,
        body
      );
      setSelectedCoupon(null);
      fetchCoupons();
      message.success("Coupon aggiornato con successo");
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function handleCouponBottomSheetConfirm(post) {
    console.log("post :", post);
    if (post.id) {
      return handleUpdateCoupon(post);
    } else {
      return handleCreateCoupon(post);
    }
  }

  async function handleDeleteCoupon(post) {
    try {
      if (!post?.id) {
        return;
      }

      await api.deleteResource("/coupons", token, post.id);
      fetchCoupons();

      message.success("Coupon eliminato con successo");
    } catch (error) {
      console.log("error: ", error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function handleCouponBottomSheetCancel() {
    setSelectedCoupon(null);
  }

  function renderActions(row) {
    return (
      <div className="actions">
        <EditButton onClick={() => setSelectedCoupon(row)} />

        <StyledPopconfirm
          onConfirm={() => handleDeleteCoupon(row)}
          placement="left"
          title="Sei sicuro di voler eliminare questo coupon?"
        >
          <DeleteButton />
        </StyledPopconfirm>
      </div>
    );
  }

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setImportedCoupons(results.data);
      },
    });
  };

  async function createCoupons() {
    importedCoupons?.map(async (coupon) => {
      coupons?.find((x) => x?.code === coupon?.COD)
        ? await handleUpdateCoupon(
            getCouponBody(coupon),
            coupons?.find((x) => x?.code === coupon?.COD)?.id
          )
        : await handleCreateCoupon(getCouponBody(coupon));
    });
  }

  function getCouponBody(coupon) {
    const body = {
      code: coupon?.COD,
      name: coupon?.Nome,
      descrizione: coupon?.Descrizione,
      currency: coupon?.Percentuale === "NO" ? 1 : 2,
      EAN: coupon?.EAN,
      validDate: [
        moment(coupon?.["Inizio Validita"], "DD/MM/YYYY"),
        moment(coupon?.["Fine Validita"], "DD/MM/YYYY"),
      ],

      value: coupon?.Valore,
      addresses: coupon?.["Mail Associata"],
      shopCoupon: coupon?.["Utilizzo in negozio"] === "SI",
      image: coupon?.Immagine,
      minPoints: coupon?.["Min Punti"],
    };

    return body;
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const defaultCoupon = {
    published: false,
  };

  async function handleUpdateIndex(category) {
    try {
      let body = {
        ...category,
      };

      await api.updateResource(`/coupons`, token, body.id, body);
    } catch (error) {
      console.error(error);
      message.error("Si è verificato un errore. Riprovare più tardi");
    }
  }

  function setSortIndex(subCategory, index) {
    const newBody = {
      ...coupons?.find((x) => x.id === subCategory.id),
      sortIndex: index,
    };

    handleUpdateIndex(newBody, false);
  }

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setSortedCoupons((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        const newArray = arrayMove(prev, activeIndex, overIndex);
        console.log("new array : ", newArray);
        newArray.map((x, index) => setSortIndex(x, index));

        return newArray;
      });
    }
  };

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );

  return (
    <div className={`${className}`}>
      <h1 className="page-title">Coupons</h1>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <AppButton
          uxType="secondary"
          onClick={() => setSelectedCoupon(defaultCoupon)}
        >
          Nuovo coupon
        </AppButton>
        <div style={{ marginLeft: 10 }}>
          <AppButton uxType="secondary" onClick={() => setOpenSelectFile(true)}>
            Importa file
          </AppButton>
        </div>
      </div>

      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          // rowKey array
          items={coupons.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey={(row) => row.id}
            dataSource={sortedCoupons}
            pagination={{
              ...pagination,
              total: totalRecordsNumber,
              pageSize: sortedCoupons?.length,
            }}
            onChange={(pagination) => fetchCoupons({ pagination })}
            columns={getColumns(renderActions)}
            loading={loading}
            components={{
              body: {
                row: Row,
              },
            }}
          />
        </SortableContext>
      </DndContext>
      <CouponsFormBottomSheet
        open={selectedCoupon ? true : false}
        post={selectedCoupon}
        onCancel={handleCouponBottomSheetCancel}
        onConfirm={handleCouponBottomSheetConfirm}
      />

      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        open={openSelectFile}
        onOk={() => createCoupons()}
        onCancel={() => setOpenSelectFile(false)}
      >
        <input
          onChange={changeHandler}
          type="file"
          name="file"
          accept=".csv"
          style={{ display: "block", margin: "10px auto" }}
        />
      </Modal>
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Coupons.propTypes = {
  className: PropTypes.string.isRequired,
};

Coupons.defaultProps = {};

// ----------------------------------------------------------------------------

const StyledCoupons = styled(Coupons)`
  & {
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledCoupons;

function getColumns(renderActions) {
  return [
    {
      key: "sort",
    },
    {
      title: "Nome",
      key: "name",
      dataIndex: "name",
    },

    {
      key: "actions",
      render: renderActions,
    },
  ];
}
