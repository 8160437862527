import PropTypes from "prop-types";
import React, { useEffect, Image, useState } from "react";
import styled from "styled-components";

import BottomSheet from "../../../../_shared/components/BottomSheet";
import Input from "../../../../_shared/components/FormItems/AppInput";

import useForm from "../../../../_shared/hooks/useForm";
import StyledImageUploadInput from "../../../../_shared/components/FormItems/ImageUploadInput";

import RichText from "../../../../_shared/components/WYSIWYGEditor";
import { FaFilePdf, FaImage } from "react-icons/fa";
import { Switch, DatePicker, Radio, Form, Modal } from "antd";
import moment from "moment";
import AppButton from "../../../../_shared/components/AppButton";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

// ----------------------------------------------------------------------------

function CouponsFormBottomSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, post, onConfirm, coupons, ...sheetProps } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const initialValues = post ?? {};

  const [formValues, setFormValue, setFormValues, clearForm] =
    useForm(initialValues);

  useEffect(() => {
    if (open) {
      setFormValues(post ?? {});
    } else {
      clearForm();
    }
  }, [open]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleFormValueChange(key) {
    return function (value) {
      console.log(">>> handleFormValueChange: ", key, value);
      setFormValue(key, value);
    };
  }

  function handleConfirm() {
    onConfirm?.(formValues);
  }

  function addPeople() {
    handleFormValueChange("addresses")([
      ...(formValues?.addresses ?? []),
      formValues?.person,
    ]);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <BottomSheet
      className={props.className}
      open={open}
      snapPoint={80}
      title={post?.id ? "Modifica Coupon" : "Nuovo Coupon"}
      onConfirm={handleConfirm}
      {...sheetProps}
    >
      <div>
        {/* <h3
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          UTILIZZO
        </h3>
        <Radio.Group
          name="radiogroup"
          defaultValue={true}
          value={formValues?.shopCoupon}
          style={{ marginBottom: 40 }}
          onChange={(e) =>
            handleFormValueChange("shopCoupon")(e?.target?.value)
          }
        >
          <Radio value={true}>In negozio</Radio>
          <Radio value={false}>Online</Radio>
        </Radio.Group> */}
        <Input
          className="form-control"
          label="Nome"
          value={formValues.name}
          onChange={handleFormValueChange("name")}
        />
        {/* <Input
          className="form-control"
          label="Codice"
          value={formValues.code}
          onChange={handleFormValueChange("code")}
        /> */}
        {/* <div style={{ marginBottom: 20 }}>
          <Input
            className="form-control"
            label="Indirizzo email"
            value={formValues.person}
            onChange={handleFormValueChange("person")}
          />
          <div style={{ marginTop: 20 }}>
            {formValues?.addresses?.join(", ")}
          </div>
          <AppButton onClick={() => addPeople()}>Aggiungi email</AppButton>
        </div> */}
        <text>Descrizione coupon</text>
        <RichText
          className="input inner"
          label="Testo"
          value={formValues?.description ?? ""}
          onChange={handleFormValueChange("description")}
        />
        <Input
          className="form-control"
          label="Codice EAN"
          value={formValues.EAN}
          onChange={handleFormValueChange("EAN")}
        />

        <div
          style={{
            display: "flex",
            width: 200,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Input
            label="Valore"
            style={{ width: 100 }}
            value={formValues.value}
            onChange={handleFormValueChange("value")}
          />
          <Radio.Group
            name="radiogroup"
            defaultValue={1}
            value={parseInt(formValues?.currency)}
            style={{ marginLeft: 20 }}
            onChange={(e) =>
              handleFormValueChange("currency")(e?.target?.value)
            }
          >
            <Radio value={1}>€</Radio>
            <Radio value={2}>%</Radio>
          </Radio.Group>
        </div>
        <h3
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 30,
          }}
        >
          RANGE DI VALIDITÀ
        </h3>
        {/*  */}

        {
          <RangePicker
            format="DD-MM-YYYY"
            defaultValue={[
              dayjs(post?.validDate?.[0]),
              dayjs(post?.validDate?.[1]),
            ]}
            showTime
            onChange={handleFormValueChange("validDate")}
          />
        }

        <h3
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 30,
          }}
        >
          IMMAGINE
        </h3>
        <div>
          <StyledImageUploadInput
            onUpload={handleFormValueChange("image")}
          ></StyledImageUploadInput>
          {typeof formValues?.image === "string" && (
            <img
              alt=""
              style={{
                marginLeft: 50,
                maxHeight: 200,
                objectFit: "contain",
              }}
              src={formValues?.image}
            />
          )}
        </div>
        {/* <h3
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 30,
          }}
        >
          IMMAGINE Dettaglio
        </h3>
        <div>
          <StyledImageUploadInput
            onUpload={handleFormValueChange("detailImage")}
          ></StyledImageUploadInput>
          {typeof formValues?.detailImage === "string" && (
            <img
              alt=""
              style={{
                marginLeft: 50,
                maxHeight: 200,
                objectFit: "contain",
              }}
              src={formValues?.detailImage}
            />
          )}
        </div> */}
      </div>
    </BottomSheet>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CouponsFormBottomSheet.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  post: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
};

CouponsFormBottomSheet.defaultProps = {};

// ----------------------------------------------------------------------------

const CouponsFormBottomSheetWithStyle = styled(CouponsFormBottomSheet)`
  & {
    .sheet-content {
      max-width: 1000px;

      .form-control {
        margin-bottom: 20px;
        width: 500px;
        max-width: 500px;
      }
    }
    .pdf-icn {
      margin-top: 50px;
      margin-left: 50px;
      font-size: 50px;
      color: ${(props) => props?.theme?.colors?.dropzoneTextColor};
    }
  }
`;
// ----------------------------------------------------------------------------

export default CouponsFormBottomSheetWithStyle;
