import { message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import Logo from "../../_shared/images/LOGO.png"

import * as api from "../../../api/Local"

import AppButton from "../../_shared/components/AppButton"
import AppInput from "../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../_shared/context/AppStateStore"
import CommandLine from "./CommandLine"

// ----------------------------------------------------------------------------

function _AuthPage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const defaultValues =
        //eslint-disable-next-line
        process.env.NODE_ENV !== "production"
            ? { email: "s.loparo@no-gravity.it", password: "123456" }
            : {}
    const [email, setEmail] = useState(defaultValues?.email)
    const [password, setPassword] = useState(defaultValues?.password)

    const [, dispatch] = useStateValue()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            const response = await api.signIn(email, password)
            dispatch({ type: "SIGN_IN_FULFILLED", payload: response })
        } catch (e) {
            console.log(e)
            message.error("Non è stato possibile effettuare il login.")
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="column">
                <form id="signup" className="auth-box" onSubmit={handleSubmit}>
                    <h2>Isola dei Tesori's app CMS</h2>
                    <AppInput
                        value={email}
                        onChange={setEmail}
                        label="E-mail"
                        className="input"
                        autocomplete="username"
                    />
                    <AppInput
                        value={password}
                        onChange={setPassword}
                        label="Password"
                        hiddenText
                        className="input"
                        autocomplete="password"
                    />
                    <AppButton
                        className="button"
                        uxType="primary"
                        htmlType="submit"
                    >
                        Accedi
                    </AppButton>
                </form>
            </div>

            <div className="column bg-primary">
                <img alt="" style={{ width: 250 }} src={Logo} />
            </div>

            <CommandLine />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AuthPage.propTypes = {
    className: PropTypes.string.isRequired,
}

_AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const AuthPage = styled(_AuthPage)`
    & {
        width: 100vw;
        height: 100vh;
        display: flex;

        .column {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .bg-primary {
            background-color: ${(props) => props.theme.colors.primary};
        }

        .auth-box {
            padding: 30px 0px;
            min-height: 300px;
            width: 400px;
            max-width: 600px;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            h2 {
                margin-bottom: 30px;
            }
            .input {
                margin-bottom: 20px;
            }
            .button {
                width: 300px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AuthPage
