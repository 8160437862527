import PropTypes from "prop-types";
import React from "react";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import styled from "styled-components";

import { useStateValue } from "../_shared/context/AppStateStore";
import AuthPage from "./AuthPage";
import LoggedApp from "./LoggedApp";
import Coupons from "./Coupons";

// ----------------------------------------------------------------------------

function _RoutedApp(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [{ user }] = useStateValue();

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  let routedContent = null;

  let routes = [];

  if (user) {
    routes = [
      {
        path: "/auth/*",
        element: <Navigate to="/" />,
      },

      {
        path: "/*",
        element: <LoggedApp />,
      },
      {
        path: "/mobileApp/coupons",
        element: <Coupons />,
      },
    ];
  } else {
    routes = [
      {
        path: "*",
        element: <Navigate to="auth" />,
      },
      {
        path: "/auth/*",
        element: <AuthPage />,
      },
      {
        path: "/mobileApp/coupons",
        element: <Coupons />,
      },
    ];
  }

  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Router>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_RoutedApp.propTypes = {
  className: PropTypes.string.isRequired,
};

_RoutedApp.defaultProps = {};

// ----------------------------------------------------------------------------

const RoutedApp = styled(_RoutedApp)`
  & {
  }
`;
// ----------------------------------------------------------------------------

export default RoutedApp;
