import { Table } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { GoPlus as PlusIcon } from "react-icons/go";
import styled from "styled-components";

import Button from "../AppButton";

//------------------------------------------------------------------------------

function _AppTable(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, onCreate, ...tableProps } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={className}>
      {onCreate && (
        <Button onClick={onCreate} className="btn-create">
          <PlusIcon />
        </Button>
      )}
      <Table className={"data-table"} {...tableProps} />
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppTable.propTypes = {
  className: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
};

_AppTable.defaultProps = {};

// ----------------------------------------------------------------------------

const AppTable = styled(_AppTable)`
  & {
    .btn-create {
      margin-bottom: 10px;
      svg {
        width: 22px;
        height: 22px;
      }
    }

    .data-table {
      border-radius: 6px 6px 0 0;

      background-color: ${(props) => props.theme.colors.white};
      overflow-x: auto;

      .ant-table-body {
        table {
          border-radius: 6px 6px 0 0;

          background-color: ${(props) => props.theme.colors.light};

          th,
          td {
            border-bottom-color: ${(props) => props.theme.colors.light};
          }
        }
      }

      .ant-table-thead > tr > th {
        background-color: transparent;
        font-weight: 600;
      }

      .ant-table-row {
        cursor: pointer;

        .device-id {
          font-size: 10px;
        }
      }

      .ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
        background-color: transparent;
        border-color: ${(props) => props.theme.colors.primary};

        a {
          color: ${(props) => props.theme.colors.primary};
        }
      }

      .ant-table-placeholder {
        background-color: transparent;
      }
      .ant-pagination-options {
        display: none;
      }
    }
  }
`;
// ----------------------------------------------------------------------------

export default AppTable;
